// JavaScript Document
import { PublicClientApplication} from '@azure/msal-browser';


export class msalClient {

    constructor(host){
        this.msalConfig = {
            auth: {
                 clientId: "95063d53-e14d-48bf-bf88-e11387f54bdf",
                 authority: "https://login.microsoftonline.com/ac62b8a0-993d-4c2b-b0bd-5c7926a52c7c",
                 redirectUri: "https://"+host,
                 navigateToLoginRequestUrl: true
            },
            cache: {
                 cacheLocation: "localStorage",
                 storeAuthStateInCookie: false
            }
        }
        this.loginRequest = {
            scopes: ["User.Read","Presence.Read.All", "Presence.Read","Calendars.Read", "Calendars.ReadBasic"]	 
        };        
        this.app = new PublicClientApplication(this.msalConfig);
        this.accounts = null;
    }

    async initialize(){
        await this.app.initialize();
    }

    getAccount(){
        if(this.accounts.length == 1){
            return this.accounts[0];
        }else{
            return false;
        }

    }
    getAccessToken = (callback) => {
        this.accounts = this.app.getAllAccounts();
        if(this.accounts.length === 1){
            let expires = new Date(this.accounts[0].idTokenClaims.exp * 1000 );
            if(expires < new Date()){
                this.app.loginPopup(this.loginRequest)
                        .then( response=>this.handleResponse(response, callback) )
                        .catch(handleError);			
            }else{
                //Add account to request for cached token
                this.loginRequest.account = this.accounts[0];
                this.app.acquireTokenSilent(this.loginRequest)
                    .then(response=>this.handleResponse(response, callback))
                    .catch(handleError);
            }
        }else {
            this.app.loginPopup(this.loginRequest)
                        .then(response=>this.handleResponse(response, callback))
                        .catch(handleError);
        }

    }
    handleResponse(response, callback){
        if(response !== null){
            this.accounts = this.app.getAllAccounts();
            this.app.setActiveAccount(this.accounts[0]);
           // var accessToken = response.accessToken;
           // let expires = new Date(this.accounts[0].idTokenClaims.exp * 1000 );
           /*
            let data = { 
                azureid: this.accounts[0].localAccountId,
                accessToken: accessToken
            }/*
            fetch('/api/token', {
                method: "POST",
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((json)=> { if(json) {callback(accessToken)}});
            */
           //console.log(this.accounts);
            
        }
    }    
}

function  handleError(error){
        console.log('Error',error);
 }
